import Icon from '@mrs-ui/Icon'
import { Link } from 'gatsby'
import React from 'react'

const TopBar = () => {
  return (
    <div className="topbar">
      <Link
        to="/careers"
        aria-label="MRS Careers"
        className="text-mini ml-1.5 mr-1.5 text-shade700 link-transition hover:text-primary"
      >
        <span>Careers</span>
      </Link>
      <div className="text-mini font-bold text-shade400">|</div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        aria-label="WorthIT Fixed Assets (External Link)"
        href="https://www.worthitfixedassets.com"
        className="text-mini ml-1.5 mr-1.5 text-shade700 link-transition hover:text-primary"
      >
        <span>WorthIT Fixed Assets</span>
      </a>
      <div className="text-mini font-bold text-shade400">|</div>
      <a
        target=""
        href="tel:+14166202720"
        className="text-mini ml-1.5 mr-1.5 text-shade700 link-transition hover:text-primary"
      >
        <span>+1 (416) 620 2720</span>
      </a>
      <div className="text-mini font-bold text-shade400">|</div>
      <a
        target=""
        href="mailto:info@mrscompany.com"
        aria-label="MRS Email"
        className="text-mini ml-1.5 mr-1.5 text-shade700 link-transition hover:text-primary"
      >
        <span>info@mrscompany.com</span>
      </a>
      <div className="text-mini font-bold text-shade400">|</div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        aria-label="MRS GitHub"
        href="https://github.com/MRSCompanyLtd"
        className="text-mini ml-1.5 mr-1.5 text-shade700 link-transition hover:text-primary"
      >
        <span>
          <Icon name="GitHub" className="h-4 w-4 flex fill-current" />
        </span>
      </a>
      <div className="text-mini font-bold text-shade400">|</div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        aria-label="MRS Twitter"
        href="https://twitter.com/_MRSCompany"
        className="text-mini ml-1.5 mr-1.5 text-shade700 link-transition hover:text-primary"
      >
        <span>
          <Icon name="Twitter" className="h-4 w-4 flex fill-current" />
        </span>
      </a>
      <div className="text-mini font-bold text-shade400">|</div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        aria-label="MRS LinkedIn"
        href="https://ca.linkedin.com/company/m-r-s--company-ltd-"
        className="text-mini ml-1.5 mr-1.5 text-shade700 link-transition hover:text-primary"
      >
        <span>
          <Icon name="Linkedin" className="h-4 w-4 flex fill-current" />
        </span>
      </a>
    </div>
  )
}
export default TopBar
