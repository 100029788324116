import React from 'react'

type Item = {
  label: string
  path?: string
}

const items: Array<Item> = [
  {
    label: '+1 (416) 620-2720',
    path: 'tel:4166202720',
  },
  {
    label: '305-75 International Blvd.',
    path: 'https://www.google.com/maps/place/75+International+Blvd,+Etobicoke,+ON+M9W+6L9/@43.677432,-97.5152271,4z/data=!4m5!3m4!1s0x882b39957e3adeed:0x7b2e272649cde2ba!8m2!3d43.677432!4d-79.5855402',
  },
  {
    label: 'Etobicoke, ON, M9W 6L9',
    path: 'https://www.google.com/maps/place/75+International+Blvd,+Etobicoke,+ON+M9W+6L9/@43.677432,-97.5152271,4z/data=!4m5!3m4!1s0x882b39957e3adeed:0x7b2e272649cde2ba!8m2!3d43.677432!4d-79.5855402',
  }
]

const itemsUsa: Array<Item> = [
  {
    label: '+1 (727) 554-4377',
    path: 'tel:7275544377',
  },
  {
    label: '301A-1400 Hope Rd',
    path: 'https://www.google.com/maps/place/1400+Hope+Rd,+Maitland,+FL+32751/@28.6315333,-81.3833654,16z/data=!3m1!4b1!4m6!3m5!1s0x88e770555182c7c1:0x31d2c2d1abe07f7a!8m2!3d28.6315333!4d-81.3833654!16s%2Fg%2F11bw3_sjlw?entry=ttu&g_ep=EgoyMDI1MDMyNS4xIKXMDSoASAFQAw%3D%3D'
  },
  {
    label: 'Maitland, FL, 32751',
    path: 'https://www.google.com/maps/place/1400+Hope+Rd,+Maitland,+FL+32751/@28.6315333,-81.3833654,16z/data=!3m1!4b1!4m6!3m5!1s0x88e770555182c7c1:0x31d2c2d1abe07f7a!8m2!3d28.6315333!4d-81.3833654!16s%2Fg%2F11bw3_sjlw?entry=ttu&g_ep=EgoyMDI1MDMyNS4xIKXMDSoASAFQAw%3D%3D'
  }
]

const ContactMenu = () => {
  return (
    <ul className="list-none flex flex-col p-0">
      <li className='mt-0'>
        <a
          href="mailto:info@mrscompany.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-small text-shade700 link-transition hover:text-shade500 hover:no-underline mb-1"
            >
          info@mrscompany.com
        </a>
      </li>
      <li className='mt-2'>Canada</li>
      {items.map((i: Item) => (
        <li key={i.label} className="block my-1">
          <a
            href={i.path}
            target="_blank"
            rel="noopener noreferrer"
            className="text-small text-shade700 link-transition hover:text-shade500 hover:no-underline"
          >
            {i.label}
          </a>
        </li>
      ))}
      <li className='mt-2'>USA</li>
      {itemsUsa.map((i: Item) => (
        <li key={i.label} className="block my-1">
          <a
            href={i.path}
            target="_blank"
            rel="noopener noreferrer"
            className="text-small text-shade700 link-transition hover:text-shade500 hover:no-underline"
          >
            {i.label}
          </a>
        </li>
      ))}
    </ul>
  )
}
export default ContactMenu
