import { Link } from 'gatsby'
import React from 'react'
import { ContentCardProps } from './ContentCard.types'

const ContentCard: React.FC<ContentCardProps> = ({
  linkText = 'Read more',
  ...props
}) => {
  return (
    <Link
      to={props.link}
      className="text-contrast block border border-shade200 bg-base focus-outline h-full hover:no-underline"
    >
      <div style={{ height: '200px' }}>
        <img
          src={props.imageSourceUrl}
          alt={`Featured image for ${props.title}`}
          className="h-full w-full object-cover"
          loading="lazy"
        />
      </div>
      <div className="p-gap-mini">
        {props.date && (
          <div className="mb-1 text-mini text-shade400">{props.date}</div>
        )}
        <h4>{props.title}</h4>
        <div
          className="text-small mt-2"
          dangerouslySetInnerHTML={{
            __html: props.excerpt,
          }}
        />
        <p className="text-small">
          <span className="pointer-events-none styled-link focus-outline">
            {linkText}
          </span>
        </p>
      </div>
    </Link>
  )
}

export default ContentCard
