import { Link } from 'gatsby'
import React from 'react'

type Item = {
  label: string
  path: string
}

const items: Array<Item> = [
  {
    label: 'About MRS',
    path: '/about',
  },
  {
    label: 'Services & Expertise',
    path: '/services',
  },
  {
    label: 'Clients',
    path: '/clients',
  },
  {
    label: 'Case Studies',
    path: '/client-success',
  },
  {
    label: 'WorthIT Fixed Assets',
    path: '/solution/worthit-fixed-assets',
  },
  {
    label: 'Blog',
    path: '/blog',
  },
  {
    label: 'Careers',
    path: '/careers',
  }
]

const MainMenu = () => {
  return (
    <ul className="list-none p-0">
      {items.map((i: Item) => (
        <li key={i.path} className="block mb-1.5">
          <Link
            to={i.path}
            className="text-small text-shade700 link-transition hover:text-shade500 hover:no-underline"
          >
            {i.label}
          </Link>
        </li>
      ))}
    </ul>
  )
}
export default MainMenu
