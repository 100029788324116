import Icon from '@mrs-ui/Icon'
import React from 'react'
import { AccordionProps } from './Accordion.types'
import './styles.scss'

const Accordion: React.FC<AccordionProps> = ({ ...props }) => {
  return (
    <details className="accordion__details">
      <summary className="accordion__summary">
        <h3>{props.label}</h3>
        <Icon name="Plus" className="accordion__icon" />
      </summary>
      <div className="accordion__content">{props.content}</div>
    </details>
  )
}

export default Accordion
