import React from 'react'

import { Button } from '@mrs-ui'

type Item = {
  label: string
  path: string
  ariaLabel: string
}

export const mainMenuItems = [
  {
    label: 'About MRS',
    path: '/about',
    ariaLabel: 'Learn more about MRS',
  },
  {
    label: 'Services & Expertise',
    path: '/services',
    ariaLabel: 'Learn more the services MRS offers',
  },

  {
    label: 'Clients',
    path: '/clients',
    ariaLabel: 'View a list of our clients',
  },
  {
    label: 'Case Studies',
    path: '/client-success',
    ariaLabel: 'See the work MRS has done for clients like yours',
  },
  {
    label: 'Solutions',
    path: '/solutions',
    ariaLabel: 'See the solutions MRS offers',
  },
  {
    label: 'Blog',
    path: '/blog',
    ariaLabel: 'View the MRS blog',
  },
]

const MainMenu = () => {
  return (
    <ul className="ml-auto flex items-center flex-grow pl-6 list-none">
      {mainMenuItems.map((i: Item) => (
        <li key={i.path} className="pl-6">
          <Button
            link={i.path}
            aria-label={i.ariaLabel}
            data-nav-link={i.label}
            appearance="clear"
            size="compact"
          >
            {i.label}
          </Button>
        </li>
      ))}
      <div className="ml-auto flex">
        <li className="pl-6">
          <Button
            link="/contact"
            aria-label="Contact MRS Company"
            appearance="primary"
            shape="pill"
          >
            Contact MRS
          </Button>
        </li>
      </div>
    </ul>
  )
}
export default MainMenu
