import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { Container, Section } from '@components/common'
import { useLocation } from '@reach/router'
import ContentCard from '@components/ContentCard'

type ImageNode = {
  sourceUrl: string
}
type FeaturedImage = {
  node: ImageNode
}
type Post = {
  id: string
  title: string
  excerpt: string
  slug: string
  date: string
  featuredImage: FeaturedImage
}
const RecentPosts = () => {
  const { pathname } = useLocation()

  const data = useStaticQuery(graphql`
    query {
      posts: allWpBlogPost(limit: 3) {
        nodes {
          title
          excerpt
          id
          slug
          date(formatString: "dddd, MMMM D YYYY")
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `)
  return (
    <Section verticalSpacing="default" className="bg-shade50">
      <Container>
        <h3 className="mb-gap-mini">Recent Posts</h3>
        <div className="flex flex-wrap -mx-5">
          {data.posts.nodes.map((post: Post) => (
            <div key={post.slug} className="sm:w-1/2 md:w-1/3 w-full px-5 mb-gap-mini">
              <ContentCard
                link={`/blog/${post.slug}`}
                imageSourceUrl={post.featuredImage.node.sourceUrl}
                title={post.title}
                excerpt={post.excerpt}
                linkText="Read the post"
              />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  )
}
export default RecentPosts
