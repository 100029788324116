exports.components = {
  "component---src-components-templates-blog-post-tsx": () => import("./../../../src/components/templates/BlogPost.tsx" /* webpackChunkName: "component---src-components-templates-blog-post-tsx" */),
  "component---src-components-templates-case-study-tsx": () => import("./../../../src/components/templates/CaseStudy.tsx" /* webpackChunkName: "component---src-components-templates-case-study-tsx" */),
  "component---src-components-templates-solution-tsx": () => import("./../../../src/components/templates/Solution.tsx" /* webpackChunkName: "component---src-components-templates-solution-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-client-success-tsx": () => import("./../../../src/pages/client-success.tsx" /* webpackChunkName: "component---src-pages-client-success-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lp-power-bi-services-tsx": () => import("./../../../src/pages/lp/power-bi-services.tsx" /* webpackChunkName: "component---src-pages-lp-power-bi-services-tsx" */),
  "component---src-pages-lp-sharepoint-consulting-tsx": () => import("./../../../src/pages/lp/sharepoint-consulting.tsx" /* webpackChunkName: "component---src-pages-lp-sharepoint-consulting-tsx" */),
  "component---src-pages-lp-sharepoint-intranet-consulting-tsx": () => import("./../../../src/pages/lp/sharepoint-intranet-consulting.tsx" /* webpackChunkName: "component---src-pages-lp-sharepoint-intranet-consulting-tsx" */),
  "component---src-pages-lp-sharepoint-services-toronto-tsx": () => import("./../../../src/pages/lp/sharepoint-services-toronto.tsx" /* webpackChunkName: "component---src-pages-lp-sharepoint-services-toronto-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-servicesv-2-tsx": () => import("./../../../src/pages/servicesv2.tsx" /* webpackChunkName: "component---src-pages-servicesv-2-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

